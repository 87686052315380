import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {history} from '@/Store/history';
import {store} from '@/Store';
import {ConfigProvider} from "antd";
import antdLang from 'antd/lib/locale-provider/ru_RU';
import Switch from './Switch';

const Root = () => {
	//console.log(`index is here`);
    return (
        <ConfigProvider
			locale={antdLang}
			getPopupContainer={() => document.getElementById('layout') || document.body}
		>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Switch />
                </ConnectedRouter>
            </Provider>
        </ConfigProvider>
    )
};

export default Root;
