import Icon from "@ant-design/icons";

export const InventoryIcon = props =>
    <Icon component={() => {
        return (
            <>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 9.99994H13V11.9999H7V9.99994Z" fill="#00263E"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M2 -6.10352e-05H18C19 -6.10352e-05 20 0.899939 20 1.99994V5.00994C20 5.72994 19.57 6.34994 19 6.69994V17.9999C19 19.0999 17.9 19.9999 17 19.9999H3C2.1 19.9999 1 19.0999 1 17.9999V6.69994C0.43 6.34994 0 5.72994 0 5.00994V1.99994C0 0.899939 1 -6.10352e-05 2 -6.10352e-05ZM3 17.9999H17V6.99994H3V17.9999ZM2 4.99994H18V1.99994H2V4.99994Z"
                          fill="#00263E"/>
                </svg>
            </>
        )
    }} {...props} />;

export const DollarIcon = props =>
    <Icon component={() => (
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.99853 7.9C3.82441 7.31 3.12525 6.7 3.12525 5.75C3.12525 4.66 4.09258 3.9 5.7112 3.9C7.41601 3.9 8.04813 4.75 8.1056 6H10.2222C10.1552 4.28 9.14956 2.7 7.14784 2.19V0H4.27456V2.16C2.4165 2.58 0.922397 3.84 0.922397 5.77C0.922397 8.08 2.75172 9.23 5.42387 9.9C7.81827 10.5 8.29715 11.38 8.29715 12.31C8.29715 13 7.82785 14.1 5.7112 14.1C3.73821 14.1 2.96243 13.18 2.83792 12H0.75C0.864931 14.19 2.43566 15.42 4.27456 15.83V18H7.14784V15.85C9.01547 15.5 10.5 14.35 10.5 12.3C10.5 9.46 8.17264 8.5 5.99853 7.9Z"
                fill="#DC4405"/>
        </svg>
    )} {...props}/>

export const CalendarIcon = props =>
    <Icon component={() => (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.83333 8.16669H5.5V9.83335H3.83333V8.16669ZM15.5 4.00002V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00002C0.508333 3.08335 1.24167 2.33335 2.16667 2.33335H3V0.666687H4.66667V2.33335H11.3333V0.666687H13V2.33335H13.8333C14.75 2.33335 15.5 3.08335 15.5 4.00002ZM2.16667 5.66669H13.8333V4.00002H2.16667V5.66669ZM13.8333 15.6667V7.33335H2.16667V15.6667H13.8333ZM10.5 9.83335H12.1667V8.16669H10.5V9.83335ZM7.16667 9.83335H8.83333V8.16669H7.16667V9.83335Z"
                fill="#00263E"/>
        </svg>
    )} {...props}/>

