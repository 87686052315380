import * as types from './types';
import {call, put, takeEvery} from '@redux-saga/core/effects';
import {api} from '@/api';
import {workerMiddleware} from '@/services/worker-middleware';

export const actions = {
    getSalary: (payload) => ({
        type: types.GET_SALARY_ACTION,
        payload
    }),
    getSalaryMonths: (payload) => ({
        type: types.GET_SALARY_MONTHS_ACTION,
        payload
    }),
    getSalaryYears: (payload) => ({
        type: types.GET_SALARY_YEARS_ACTION,
        payload
    })
};

export function* saga() {
    yield takeEvery(types.GET_SALARY_ACTION, workerMiddleware, {worker: function* ({payload}) {
        const response = yield call(api.salary, payload);
        if (response.data) {
            yield put({
                type: types.GET_SALARY_RECEIVER,
                payload: response.data
            });
        }
    }});

    yield takeEvery(types.GET_SALARY_MONTHS_ACTION, workerMiddleware, {worker: function* ({payload}) {
        const response = yield call(api.salaryMonths, payload);
        if (response.data) {
            yield put({
                type: types.GET_SALARY_MONTHS_RECEIVER,
                payload: response.data
            });
        }
    }});

    yield takeEvery(types.GET_SALARY_YEARS_ACTION, workerMiddleware, {worker: function* ({payload}) {
        const response = yield call(api.salaryYears, payload);
        if (response.data) {
            yield put({
                type: types.GET_SALARY_YEARS_RECEIVER,
                payload: response.data
            });
        }
    }});
}
