import React from 'react';
import {Col, Layout, Row} from 'antd';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {LeftOutlined} from '@ant-design/icons';
import {actions} from '@/redux/auth/actions';

const {Header} = Layout;
const isMainPage = window.location.pathname === '/' || window.location.pathname === '/login';

const EmptyLayout = ({
                         children,
                         isLoading,
                         isLoggingIn,
                         resetStep,
                         step
                     }) => {
    const handleResetStep = () => {
        resetStep();
    };

    return (
        <div className={'layout layout--empty'}>
            {isLoading && <div className="layout--progress"/>}
                <>
                    <Header className={'header'} role={'header'}>
                        <Row style={{height: '100%', alignItems: 'safe center'}}>
                            <Col xs={8}>
                                {step === 2 && isLoggingIn ? (
                                    <LeftOutlined
                                        onClick={handleResetStep}
                                        className={'header--back'}
                                    />
                                ) : null}
                            </Col>
                            <Col xs={8} style={{display: 'flex', alignItems: 'safe center', justifyContent: 'center'}}>
                                {isMainPage ? (
                                    <img src="/images/logo.svg" className={'header--logo'} alt=""/>
                                ) : (
                                    <Link to={'/'}>
                                        <img src="/images/logo.svg" className={'header--logo'} alt=""/>
                                    </Link>
                                )}
                            </Col>
                        </Row>
                    </Header>
                    <div className={'layout--body'}>
                        {children}
                    </div>
                </>
        </div>
    )
};

export default connect((state) => ({
    isLoading: !!state.preloader.activeTypes.length,
    isLoggingIn: state.auth.isLoggingIn,
    step: state.auth.step
}), {
    resetStep: actions.resetToFirstStep
})(EmptyLayout);
