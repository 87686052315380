import * as types from './types';
import createReducer from '@/utils/createReducer';

const initialState = {
    years: [],
    salary: {},
    salaries: []
};

export default createReducer(initialState, {
    [types.GET_SALARY_RECEIVER](state, {payload}) {
        return {
            ...state,
            salary: payload,
        }
    },
    [types.GET_SALARY_MONTHS_RECEIVER](state, {payload}) {
        return {
            ...state,
            salaries: payload.list,
        }
    },
    [types.GET_SALARY_YEARS_RECEIVER](state, {payload}) {
        return {
            ...state,
            years: payload.list,
        }
    },
});
