import React from 'react';
import {default as Loading} from './Loading';

class LoadableRoute extends React.Component {
	state = {
		module: Loading
	};

	constructor (props) {
		super(props);

		this.props.component().then((Component) => {
			this.setState({
				module: Component.default
			});
		});
	}

	componentDidMount () {
		const {
			name = null
		} = this.props;

		if (name) {
			document.title = `ALD | ${name}`
		}
	}

	render () {
		const { module: Component } = this.state;
		const { layout: Layout } = this.props;

		return (
			<Layout>
				<Component />
			</Layout>
		)
	}
}

export default LoadableRoute;
