import React from 'react';
import {notification} from 'antd';

export const noty = (type = 'info', message, description, config) => {
	notification[type]({
		message: message,
		description: description,
		placement: 'bottomRight',
		...config
	});
};
