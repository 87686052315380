import * as types from './types';
import {call, put, takeEvery} from '@redux-saga/core/effects';
import {api} from '@/api';
import {workerMiddleware} from '@/services/worker-middleware';

export const actions = {
    getWorkday: (payload) => ({
        type: types.GET_WORKDAY_ACTION,
        payload
    }),
    getWorkdays: (payload) => ({
        type: types.GET_WORKDAYS_ACTION,
        payload
    })
};

export function* saga() {
    yield takeEvery(types.GET_WORKDAY_ACTION, workerMiddleware, {worker: function* ({payload}) {
        const response = yield call(api.workday, payload);
        if (response.data) {
            yield put({
                type: types.GET_WORKDAY_RECEIVER,
                payload: response
            });
        }
    }});

    yield takeEvery(types.GET_WORKDAYS_ACTION, workerMiddleware, {worker: function* ({payload}) {
            const response = yield call(api.workdays, payload);
            if (response.data) {
                yield put({
                    type: types.GET_WORKDAYS_RECEIVER,
                    payload: response
                });
            }
    }});
}
