import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import {history} from './history';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import {routerMiddleware} from 'connected-react-router';
import combineReducers from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

const sagaMiddlewareRun = () => {
    sagaMiddleware.run(rootSaga);
};

const middleware = applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
);

const enhancers = composeWithDevTools(middleware)
export const store = createStore(
    combineReducers(history),
    enhancers
);

sagaMiddlewareRun();
