import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Menu} from 'antd';
import {
    CalendarOutlined,
    DatabaseOutlined,
    UserOutlined,
} from '@ant-design/icons';

import {DollarIcon, InventoryIcon} from "../Icons";

const navbarKeys = {
    'home': 'salaries',
    'profile': 'profile',
    'salaries': 'salaries',
    'salary': 'salaries',
    'schedule': 'schedule',
    'payouts': 'payouts',
    'inventory': 'inventory',
}

export const DesktopHeader = () => {
    const location = useLocation();
    const arr = location.pathname.split('/');
    let key = arr[1] ? arr[1] : 'home';

    return (
        <Menu
            id="mobile-desktop__header"
            mode="inline"
            triggerSubMenuAction="click"
            selectedKeys={[navbarKeys[key]]}
            className="desktop__header--menu landscape"
        >
            <Menu.Item title={null} key="logo" className="desktop__header--logo">
                <img src="/images/logo.svg" alt=""/>
                <span className="desktop__header--logo--text">engineering company</span>
            </Menu.Item>
            <Menu.Item title={null} key="salaries" className="desktop__header--menu--item" icon={<DollarIcon/>}>
                <Link to="/salaries" className="desktop__header--menu--link-text">Зарплата</Link>
            </Menu.Item>
            <Menu.Item title={null} key="schedule" className="desktop__header--menu--item" icon={<DatabaseOutlined className="desktop__header--menu--link-icon"/>}>
                <Link to="/schedule" className="desktop__header--menu--link-text">Выходы</Link>
            </Menu.Item>
            <Menu.Item title={null} key="payouts" className="desktop__header--menu--item" icon={<CalendarOutlined className="desktop__header--menu--link-icon"/>}>
                <Link to="/payouts" className="desktop__header--menu--link-text">Выплаты</Link>
            </Menu.Item>
            <Menu.Item title={null} key="inventory" className="desktop__header--menu--item" icon={<InventoryIcon className="desktop__header--menu--link-icon"/>}>
                <Link to="/inventory" className="desktop__header--menu--link-text">Инвентарь</Link>
            </Menu.Item>
            <Menu.Item title={null} key="profile" className="desktop__header--menu--item" icon={<UserOutlined className="desktop__header--menu--link-icon"/>}>
                <Link to="/profile" className="desktop__header--menu--link-text">Профиль</Link>
            </Menu.Item>
        </Menu>
    )
}
