import * as types from './types';
import {call, put, takeEvery} from '@redux-saga/core/effects';
import {api} from '@/api';
import {history} from '@/Store/history';
import {noty} from '@/utils/notifications';
import {workerMiddleware} from '@/services/worker-middleware';
import {store} from '@/Store';

export const actions = {
    requestSmsCode: (payload) => ({
        type: types.REQUEST_SMS_CODE,
        payload
    }),
    clearToken: () => ({
        type: types.CLEAR_TOKEN
    }),
    resetToFirstStep: () => ({
        type: types.RESET_TO_FIRST_STEP
    }),
    confirmSmsCode: (payload) => ({
        type: types.CONFIRM_SMS_CODE,
        payload
    }),
    loginSuccess: (payload) => ({
        type: types.LOGIN_SUCCESS,
        payload
    }),
    logout: () => ({
        type: types.LOGOUT
    }),
    getUser: (payload) => ({
        type: types.GET_USER,
        payload
    }),
    getCompanies: (payload) => ({
        type: types.GET_COMPANIES,
        payload
    }),
    refreshAccessToken: (payload) => ({
        type: types.REFRESH_ACCESS_TOKEN,
        payload
    }),
    refreshToken: () => ({
        type: types.REFRESH_TOKEN
    }),
    getQuestions: (payload) => ({
        type: types.GET_QUESTION,
        payload
    }),
    sendAnswer: (payload) => ({
        type: types.SEND_ANSWER,
        payload
    })
};

const saveTokenToLocalStorage = (data) => {
    localStorage.setItem('access_token', data.accessToken);
    localStorage.setItem('refresh_token', data.refreshToken);
};

const removeTokenFromLocalStorage = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
};

export function* saga() {
    yield takeEvery(types.REQUEST_SMS_CODE, workerMiddleware, {worker: function* ({payload}) {
        const response = yield call(api.login, payload);
        if (response.data.success) {
            yield put({
                type: types.FIRST_STEP_SUCCESS
            });
        }
    }});

    yield takeEvery(types.CONFIRM_SMS_CODE, workerMiddleware, {worker: function* ({payload}) {
        const response = yield call(api.loginConfirm, payload);
        if (response.messages.length) {
            yield put({
                type: types.CONFIRM_SMS_CODE_RECEIVER,
                payload: {code: payload.code, error: response.messages}
            });
        } else {
			const accessToken = response.data?.tokens?.accessToken;
			const refreshToken = response.data?.tokens?.refreshToken;
			yield saveTokenToLocalStorage({accessToken, refreshToken});
            yield put(actions.loginSuccess(response.data));
			history.push('/salaries');
        }
    }});

    yield takeEvery(types.LOGOUT, workerMiddleware, {worker: function* () {
        const response = yield call(api.logout);
        if (response.data.success) {
            yield put(actions.loginSuccess({
                step: 1
            }));
            removeTokenFromLocalStorage();
            history.push('/login');
        }
    }});

    yield takeEvery(types.GET_USER, workerMiddleware, {worker: function* ({type, payload}) {
        const response = yield call(api.user, payload);
        if (Object.keys(response.data).length) {
            yield put(actions.loginSuccess({
                ...response.data,
                step: 1
            }));
        }
    }});

    yield takeEvery(types.GET_COMPANIES, workerMiddleware, {worker: function* ({type}) {
        const response = yield call(api.companies);
        if (response.data) {
            yield put({
                type: types.GET_COMPANIES_RECEIVER,
                payload: response.data
            });
        }
    }});

    yield takeEvery(types.GET_QUESTION, workerMiddleware, {worker: function* ({payload}) {
        const state = store.getState();
        const accessToken = state?.auth?.accessToken;

        const response = yield call(api.question, {
            data: payload,
            config: {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        });
        if (response.data) {
            yield put({
                type: types.SAVE_QUESTION,
                payload: response.data
            });
        }
    }});

    yield takeEvery(types.SEND_ANSWER, workerMiddleware, {worker: function* ({payload = {}}) {
        const {number = 1, question} = payload;
        const state = store.getState();
        const accessToken = state?.auth?.accessToken;
        const refreshToken = state?.auth?.refreshToken;
        const ids = state?.auth?.question?.ids || [];

        const response = yield call(api.answer, {
            data: payload,
            config: {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        });
        if (response.data.success) {
            yield put({
                type: types.SAVE_QUESTION,
                payload: {
                    number: 1,
                    ids: []
                }
            });
            //yield saveTokenToLocalStorage({accessToken, refreshToken});
            history.push('/salaries');
            noty('success', 'Ответ засчитан. Спасибо!');
        } else {
            if (number < 3) {
                const questionResponse = yield call(api.question, {
                    data: {
                        id: [
                            ...ids,
                            question
                        ]
                    },
                    config: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                });
                if (questionResponse.data.id) {
                    yield put({
                        type: types.SAVE_QUESTION,
                        payload: {
                            ...questionResponse.data,
                            number: number + 1,
                            ids: [
                                ...ids,
                                question
                            ]
                        }
                    });
                }
            } else {
                yield put(actions.loginSuccess({
                    user: {
                        blocked: true
                    },
                    tokens: null,
                    step: 3
                }));
            }
        }
    }});

    yield takeEvery(types.REFRESH_TOKEN, workerMiddleware, {worker: function* () {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = yield call(api.refreshToken, refreshToken);
        if (response.data.accessToken) {
            localStorage.setItem('access_token', response.data.accessToken);
            yield put({
                type: types.REFRESH_ACCESS_TOKEN,
                payload: response.data.accessToken
            });
        } else {
            yield put({type: types.CLEAR_TOKEN});
        }
    }});

    yield takeEvery(types.CLEAR_TOKEN, workerMiddleware, {worker: function* () {
        removeTokenFromLocalStorage();
        history.push('/login');
    }});
}
