import * as types from './types';

export const addPreloader = (payload) => ({
	type: types.SET_PRELOADER,
	payload
});

export const clearPreloader = (payload) => ({
	type: types.CLEAR_PRELOADER,
	payload
});
