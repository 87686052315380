import * as types from './types';
import createReducer from '@/utils/createReducer';

const initialState = {
    workday: null,
    workdays: [],
    hours: 0
};

export default createReducer(initialState, {
    [types.GET_WORKDAY_RECEIVER](state, {payload}) {
        return {
            ...state,
            workday: payload.data,
        }
    },
    [types.GET_WORKDAYS_RECEIVER](state, {payload}) {
        return {
            ...state,
            workdays: payload.data.list,
            hours: payload.data.hours
        }
    },
});
