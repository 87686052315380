import http from '@/services/http';

export const api = {
    login: (data) => http.post('/login', data),
    user: (data) => http.get('/user', {params: data}),
    refreshToken: (refreshToken) => http.post('/refresh-token', {token: refreshToken}),
    loginConfirm: (data) => http.post('/login-confirm', data),
    logout: () => http.get('/logout'),
    companies: () => http.get('/companies'),
    payouts: () => http.get('/payouts'),
    inventory: () => http.get('/inventory'),
    salaryYears: (data) => http.get('/salary-years', {params: data}),
    salaryMonths: (data) => http.get('/salary-months', {params: data}),
    salary: (data) => http.get('/salary', {params: data}),
    workdays: (data) => http.get('/workdays', {params: data}),
    workday: (data) => http.get('/workday', {params: data}),
    question: ({data, config}) => http.post('/question', data, config),
    answer: ({data, config}) => http.post('/answer', data, config)
};
