import axios from 'axios';
import {noty} from '@/utils/notifications';

export const actionsConfig = {
    data: {}
};

export const http = axios.create({
    baseURL: process.env.REACT_APP_API_HOST
});

http.interceptors.request.use(function (config) {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
});

http.interceptors.response.use((response) => {
    const {data} = response;

    if (data.data.success === false) {
        data.messages.map(({status, message}) => {
            noty(status, message);
        });
    }

    return data;
}, (error) => {
    throw error;
});

export default http;
