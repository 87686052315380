import {combineReducers} from 'redux';
import auth from '@/redux/auth/reducer';
import payouts from '@/redux/payouts/reducer';
import inventory from '@/redux/inventory/reducer';
import salary from '@/redux/salary/reducer';
import preloader from '@/redux/preloader/reducer';
import workdays from '@/redux/workdays/reducer';
import {connectRouter} from 'connected-react-router';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    payouts,
    inventory,
    preloader,
    workdays,
    salary
});

export default rootReducer;
