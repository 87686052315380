import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Components/Root';
import '@/styles/antd/index.css';
import '@/styles/index.scss';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Root />,
  rootElement
);
