export const REQUEST_SMS_CODE = '@/auth/REQUEST_SMS_CODE';
export const CLEAR_TOKEN = '@/auth/CLEAR_TOKEN';
export const CONFIRM_SMS_CODE = '@/auth/CONFIRM_SMS_CODE';
export const CONFIRM_SMS_CODE_RECEIVER = '@/auth/CONFIRM_SMS_CODE_RECEIVER';
export const LOGIN_SUCCESS = '@/auth/LOGIN_SUCCESS';
export const FIRST_STEP_SUCCESS = '@/auth/FIRST_STEP_SUCCESS';
export const RESET_TO_FIRST_STEP = '@/auth/RESET_TO_FIRST_STEP';
export const LOGOUT = '@/auth/LOGOUT';
export const GET_USER = '@/auth/GET_USER';
export const GET_COMPANIES = '@/auth/GET_COMPANIES';
export const GET_COMPANIES_RECEIVER = '@/auth/GET_COMPANIES_RECEIVER';
export const REFRESH_ACCESS_TOKEN = '@/auth/REFRESH_ACCESS_TOKEN';
export const GET_QUESTION = '@/auth/GET_QUESTION';
export const SAVE_QUESTION = '@/auth/SAVE_QUESTION';
export const SEND_ANSWER = '@/auth/SEND_ANSWER';
export const REFRESH_TOKEN = '@/auth/REFRESH_TOKEN';
