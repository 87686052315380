import {Menu} from 'antd';
import {
    CalendarOutlined,
    DatabaseOutlined,
    DollarOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {DollarIcon, InventoryIcon} from "../Icons";

const navbarKeys = {
    '/profile': 'Профиль',
    '/salaries': 'Зарплата',
    '/salary': 'Зарплата',
    '/schedule': 'Выходы',
    '/payouts': 'Выплаты',
    '/inventory': 'Инвентарь',
}

export const MobileHeader = ({isLandscape}) => {
    const location = useLocation();
    return (
        <Menu id="mobile-header" mode={isLandscape ? 'inline' : 'horizontal'}
              defaultSelectedKeys={[navbarKeys[location.pathname]]}
              className={`${isLandscape && 'landscape'} header--menu`}>
            <Menu.Item title="Зарплата" key='Зарплата' className="header--menu--item">
                <DollarIcon className="header--menu--link-icon"/>
                <Link to="/salaries" className="header--menu--link-text">Зарплата</Link>
            </Menu.Item>
            <Menu.Item title="Выходы" key="Выходы" className="header--menu--item">
                <DatabaseOutlined className="header--menu--link-icon"/>
                <Link to="/schedule" className="header--menu--link-text">Выходы</Link>
            </Menu.Item>
            <Menu.Item title="Выплаты" key="Выплаты" className="header--menu--item">
                <CalendarOutlined className="header--menu--link-icon"/>
                <Link to="/payouts" className="header--menu--link-text">Выплаты</Link>
            </Menu.Item>
            <Menu.Item title="Инвентарь" key="Инвентарь" className="header--menu--item">
                <InventoryIcon className="header--menu--link-icon"/>
                <Link to="/inventory" className="header--menu--link-text">Инвентарь</Link>
            </Menu.Item>
            <Menu.Item title="Профиль" key="Профиль" className="header--menu--item">
                <UserOutlined className="header--menu--link-icon"/>
                <Link to="/profile" className="header--menu--link-text">Профиль</Link>
            </Menu.Item>
        </Menu>
    )
}