import * as types from './types';
import createReducer from '@/utils/createReducer';

const initialState = {
    list: [],
    total: null,
};

export default createReducer(initialState, {
    [types.GET_INVENTORY_RECEIVER](state, {payload}) {
        return {
            ...state,
            list: payload.list,
            total: payload.total,
        }
    },
});
