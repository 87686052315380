import {call, put} from 'redux-saga/effects';
import {onCatchError} from '@/services/on-catch-error';
import {addPreloader, clearPreloader} from '@/redux/preloader/actions';

export function* workerMiddleware (params, action) {
	yield put(addPreloader(action.type));
	try {
		yield call(params.worker, action);
	} catch (error) {
		onCatchError({ error, action });
	} finally {
		yield put(clearPreloader(action.type));
	}
}
