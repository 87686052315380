import UserLayout from '@/Components/Layouts/User';
import GuestLayout from '@/Components/Layouts/Guest';
import {history} from '@/Store/history';
import {store} from '@/Store';

const isAuthenticated = (component) => {
    if (store.getState().auth.user.blocked || !localStorage.getItem('access_token')) {
        history.push('/login');
    }
    return component;
};

export const routes = {
    login: {
        path: '/login',
        layout: GuestLayout,
        exact: true,
        component: () => import('@/Pages/Login'),
    },
    home: {
        path: '/',
        layout: UserLayout,
        exact: true,
        component: isAuthenticated(() => import('@/Pages/Salaries'))
    },
    profile: {
        path: '/profile',
        layout: UserLayout,
        exact: true,
        component: isAuthenticated(() => import('@/Pages/Profile'))
    },
    schedule: {
        path: '/schedule',
        layout: UserLayout,
        exact: true,
        component: isAuthenticated(() => import('@/Pages/Schedule'))
    },
    inventory: {
        path: '/inventory',
        layout: UserLayout,
        exact: true,
        component: isAuthenticated(() => import('@/Pages/Inventory'))
    },
    payouts: {
        path: '/payouts',
        layout: UserLayout,
        exact: true,
        component: isAuthenticated(() => import('@/Pages/Payouts'))
    },
    salary: {
        path: `/salary/:year/:month`,
        layout: UserLayout,
        exact: true,
        component: isAuthenticated(() => import('@/Pages/Salary'))
    },
    salaries: {
        path: '/salaries',
        layout: UserLayout,
        exact: true,
        component: isAuthenticated(() => import('@/Pages/Salaries'))
    },
    error: {
        path: '*',
        layout: UserLayout,
        exact: false,
        component: isAuthenticated(() => import('@/Pages/Error'))
    }
};
