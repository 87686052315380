import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {actions} from '@/redux/auth/actions';
import {MobileHeader} from "../Headers/MobileHeader";
import {Layout} from "antd";
import {DesktopHeader} from "../Headers/DesktopHeader";

const {Sider} = Layout;

const AuthedLayout = ({children, isLoading,}) => {
        const [collapsed, setCollapsed] = useState(Boolean(localStorage.getItem('collapsed')));
        const [width, setWidth] = useState(window.innerWidth || 0);

        function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            setCollapsed(width <= 900);
            localStorage.setItem('collapsed', (width <= 900).toString());
        }, [width])

        useEffect(() => {
            handleWindowSizeChange();
            window.addEventListener('resize', handleWindowSizeChange);
            window.addEventListener('orientationchange', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
                window.removeEventListener('orientationchange', handleWindowSizeChange);
            }
        }, []);

        const onCollapse = () => {
            setCollapsed(collapsed => {
                localStorage.setItem('collapsed', !collapsed ? 'true' : '');
                return !collapsed;
            })
        };

    const CustomTrigger = () => collapsed ? <><i>›</i></> : <><i>‹</i> <span>Свернуть</span></>

        return (
            <Layout>
                {width > 600 && (
                    <Sider
                        collapsible
                        collapsed={width > 1024 ? collapsed : true}
                        collapsedWidth={75}
                        width={240}
                        onCollapse={onCollapse}
                        style={{display: 'inline-flex'}}
                        trigger={width > 1024 ? <CustomTrigger /> : null}
                    >
                        <DesktopHeader className={'desktop--header'}/>
                    </Sider>
                )}
                <Layout>
                    <div id="layout" className={'layout layout--user'}>
                        {isLoading && <div className="layout--progress"/>}
                        {children}
                    </div>
                </Layout>
                {width <= 600 && <MobileHeader className={'header'}/>}
            </Layout>
        )
    }
;

export default connect((state) => (
        {
            isLoading: !!state.preloader.activeTypes.length,
        }
    ),
    {
        resetStep: actions.resetToFirstStep
    }
)
(AuthedLayout);
