import * as types from './types';
import createReducer from '@/utils/createReducer';

const initialState = {
    accessToken: localStorage.getItem('access_token') || null,
    refreshToken: localStorage.getItem('refresh_token') || null,
    user: {},
    phone: null,
    step: 1,
    code: null,
    companies: [],
    error: null,
    question: {}
};

export default createReducer(initialState, {
    [types.REQUEST_SMS_CODE](state, {payload}) {
        return {
            ...state,
            phone: payload.phone,
            isLoggingIn: true
        }
    },
    [types.FIRST_STEP_SUCCESS](state) {
        return {
            ...state,
            step: 2
        }
    },
    [types.RESET_TO_FIRST_STEP](state) {
        return {
            ...state,
            step: 1,
            phone: initialState.phone
        }
    },
    [types.CONFIRM_SMS_CODE_RECEIVER](state, {payload}) {
        return {
            ...state,
            code: payload.code,
            error: payload.error
        }
    },
    [types.LOGIN_SUCCESS](state, {payload}) {
        const {user, tokens, step} = payload
        return {
            ...state,
            user: user || payload,
            accessToken: tokens?.accessToken,
            refreshToken: tokens?.refreshToken,
            step,
            code: null
        }
    },
    [types.REFRESH_ACCESS_TOKEN](state, {payload}) {
        return {
            ...state,
            accessToken: payload
        }
    },
    [types.LOGOUT](state) {
        return {
            ...state,
            user: {},
            companies: [],
            phone: null,
            accessToken: null,
            refreshToken: null
        }
    },
    [types.CLEAR_TOKEN]() {
        return initialState;
    },
    [types.GET_COMPANIES_RECEIVER](state, {payload}) {
        return {
            ...state,
            companies: payload.list
        }
    },
    [types.SAVE_QUESTION](state, {payload}) {
        return {
            ...state,
            question: payload
        }
    },
});
