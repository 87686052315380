import * as types from './types';
import {call, put, takeEvery} from '@redux-saga/core/effects';
import {api} from '@/api';
import {workerMiddleware} from '@/services/worker-middleware';

export const actions = {
    getInventory: () => ({
        type: types.GET_INVENTORY_ACTION
    })
};

export function* saga() {
    yield takeEvery(types.GET_INVENTORY_ACTION, workerMiddleware, {worker: function* ({payload}) {
        const response = yield call(api.inventory, payload);
        if (response.data) {
            yield put({
                type: types.GET_INVENTORY_RECEIVER,
                payload: response.data
            });
        }
    }});
}
