import {all} from 'redux-saga/effects';
import {saga as auth} from '@/redux/auth/actions';
import {saga as payouts} from '@/redux/payouts/actions';
import {saga as inventory} from '@/redux/inventory/actions';
import {saga as salary} from '@/redux/salary/actions';
import {saga as workdays} from '@/redux/workdays/actions';

const sagasList = [
	auth,
	payouts,
	salary,
	inventory,
	workdays
];

export default function* rootSaga() {
	yield all(sagasList.map((saga) => saga()));
};
