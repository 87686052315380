import {store} from '@/Store';
import {actionsConfig} from '@/services/http';
import {actions} from '@/redux/auth/actions';
import {api} from '@/api';
import * as types from '@/redux/auth/types';
import {noty} from '@/utils/notifications';

export const onCatchError = ({error, action}) => {
	const {response = {}} = error;
	const {status, data = {}} = response;
	const refreshToken = localStorage.getItem('refresh_token');

	switch (status) {
		case 401:
			if (Object.keys(actionsConfig.data).length) {
				actionsConfig.data[action.type] = action.payload;
			} else if (refreshToken) {
				api.refreshToken(refreshToken).then((response) => {
					if (response.data.accessToken) {
						localStorage.setItem('access_token', response.data.accessToken);
						store.dispatch({
							type: types.REFRESH_ACCESS_TOKEN,
							payload: response.data.accessToken
						});
						Object.keys(actionsConfig.data).forEach((type) => {
							store.dispatch({ type, payload: actionsConfig.data[type] });
						});
					} else {
						store.dispatch(actions.clearToken());
					}
					actionsConfig.data = {};
				});
				actionsConfig.data[action.type] = action.payload;
			} else {
				store.dispatch(actions.clearToken());
				actionsConfig.data = {};
			}
			break;
		default:
			if (data.messages) {
				data.messages.map(({status, message}) => {
					noty(status, message);
				});
			}
			break;
	}
};
