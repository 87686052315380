import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {actions} from "@/redux/auth/actions";
import {Route, Switch, useLocation} from 'react-router-dom';
import {routes} from '@/routes';
import LoadableComponent from '@/Components/LoadableComponent';
import {history} from '@/Store/history';

const CustomSwitch = (props) => {
	const { user, getUser } = props;
	const location = useLocation();
	const isLoginPage = location.pathname === '/login';

	if (!isLoginPage && (user.blocked || !localStorage.getItem('access_token'))) {
		history.push('/login');
	}

	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/sw.js');
		}
		if (localStorage.getItem('access_token')) {
			getUser();
		}
		setTimeout(() => {
			const el = document.getElementById('splesh');
			if (el) {
				el.classList.add('is-hide');
				setTimeout(() => {
					el.parentNode.removeChild(el);
				}, 400);
			}
		}, 1000);
	}, []);

	return (isLoginPage || user.id) ? (
		<Switch>
			{Object.values(routes).map(({component: Component, layout: Layout, ...options}, index) => {
				const _component = () => (
					<LoadableComponent layout={Layout} component={Component}/>
				);
				return (
					<Route key={`route-${index}`} {...options} component={_component}/>
				)
			})}
		</Switch>
	) : null;
}

export default connect((state) => ({
	user: state.auth.user
}), {
	getUser: actions.getUser
})(CustomSwitch);
