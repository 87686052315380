import createReducer from '@/utils/createReducer';
import * as types from './types';

const initialState = {
	activeTypes: []
};

export default createReducer(initialState, {
	[types.SET_PRELOADER](state, type) {
		return {
			...state,
			activeTypes: [
				...state.activeTypes,
				type
			]
		}
	},
	[types.CLEAR_PRELOADER](state, type) {
		const newTypesList = [...state.activeTypes];
		newTypesList.splice(newTypesList.indexOf(type), 1);
		return {
			...state,
			activeTypes: newTypesList
		}
	}
})
